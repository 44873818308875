/**
 * @Author: Kerem Koç <keremkoc>
 * @Date:   2018-01-09T01:47:49+03:00
 * @Email:  kerem@kocapps.com
 * @Filename: css.js
 * @Last modified by:   keremkoc
 * @Last modified time: 2018-01-09T01:48:10+03:00
 */
//Require CSS
// require('../../style.css')
import './font/fonts.css';

import '../../node_modules/sweetalert2/dist/sweetalert2.css';
// lib css files:
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
// // import '../../node_modules/normalize-css/normalize.css';
import '../../node_modules/animate.css/animate.min.css';
// // import '../../node_modules/monosocialiconsfont/monosocialiconsfont.css';
import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
// import '../vendor/fontawesome-free/svg-with-js/css/fa-svg-with-js.css';
import './less/css/style.css';
import './less/css/style_v2.css';
import './less/css/responsive.css';




//Require Less
// require('./less/_main.less');
import './less/_main.less';

//
//
//
// import '../../node_modules/@fortawesome/fontawesome-pro/css/fontawesome.css';
// import '../../node_modules/@fortawesome/fontawesome-pro/css/solid.css';
// import '../../node_modules/@fortawesome/fontawesome-pro/css/brands.css';
// import '../../node_modules/@fortawesome/fontawesome-pro/css/regular.css';
// import '../../node_modules/@fortawesome/fontawesome-pro/css/light.css';

//Require Libs:
